@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html, body, #root { height: 100%; }

#root { overflow: auto; }

body {
    margin: 0;
    padding: 0;
    font-family: filson-soft, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.backgroundContainer {
    background-image: url("./icons/softpro-background.png");
    background-size: cover;
    height: calc(100% - 105px);
    margin-top: 105px;
    width: 100%;
    position: absolute;
}

.loginPage {
    display: flex;
    flex-direction: column;
}

a {
    font-size: 12px;
    text-decoration: none;
}

.toggleSwitch {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 26px;
    width: 300px;
    margin: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.toggleSwitchStandard {
    background-color: #002244;
}

.toggleSwitchSelect {
    background-color: #f33e36;
}

.inputRoot {
    width: 45%;
    position: absolute;
}

.manageNotifications {
    margin-top: 135px;
}

.helpPage {
    margin-top: 135px;
}

.suggestedDownloads {
    height: 284px;
}

.breadcrumb {
    display: flex;
    flex-direction: column;
    margin-top: 140px;
    margin-left: 300px;
}

.notificationPreferences {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 25%;
    justify-content: space-evenly;
}

.slick-arrow {
    background-color: transparent;
    font-size: 30px;
    width: 1em;
    height: 1em;
}

.slick-prev:before, .slick-next:before {
    color: #6089BD;
    font-size: 1em;
}

.rc-pagination {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    user-select: none;
    padding: 0;
}

.rc-pagination > li {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-align: center;
    vertical-align: middle;
}
